var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("b-card", [
        _c("h4", [
          _c("i", { staticClass: "fa fa-edit" }),
          _vm._v(" Service Estimates - Line Item Per AFE / PO 1 "),
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value:
                  "<p>1. Download CSV Estimates template first.</p><p>2. Fill the Estimates for service lines as they appear in the template.</p><p>3. Upload saved template.</p>",
                expression:
                  "'<p>1. Download CSV Estimates template first.</p><p>2. Fill the Estimates for service lines as they appear in the template.</p><p>3. Upload saved template.</p>'"
              }
            ],
            staticClass: "ml-2 fa fa fa-question-circle"
          })
        ]),
        _c("hr"),
        _c("div", { staticClass: "row mb-4" }, [
          _c(
            "div",
            { staticClass: "col-md-4 col-xl-6 mt-3" },
            [
              _c("h5", [
                _vm._v("Select Project "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "ml-2 fa fa fa-question-circle",
                  attrs: { title: "You may select multiple projects" }
                })
              ]),
              _c("multiselect", {
                attrs: {
                  placeholder: "Search or add project",
                  "tag-placeholder": "Search or add project",
                  "track-by": "projectid",
                  options: _vm.projectData,
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "preserve-search": true,
                  taggable: false,
                  label: "projectname"
                },
                on: {
                  input: function($event) {
                    _vm.getAfes(), (_vm.showVendors = false)
                  }
                },
                model: {
                  value: _vm.projectid,
                  callback: function($$v) {
                    _vm.projectid = $$v
                  },
                  expression: "projectid"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4 col-xl-6 mt-3" },
            [
              _c("h5", [_vm._v("Select AFE / PO")]),
              _c("multiselect", {
                attrs: {
                  placeholder: "Search or add afe",
                  "tag-placeholder": "Search or add afe",
                  "track-by": "pwcaid",
                  options: _vm.afeData,
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "preserve-search": true,
                  taggable: false,
                  label: "afenum",
                  "preselect-first": false
                },
                on: {
                  input: function($event) {
                    ;(_vm.showVendors = false),
                      _vm.getVendors(),
                      (_vm.vendorEstimates = {})
                  }
                },
                model: {
                  value: _vm.afes,
                  callback: function($$v) {
                    _vm.afes = $$v
                  },
                  expression: "afes"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4 col-xl-6 mt-3" },
            [
              _c("h5", [_vm._v("Select Vendors")]),
              _c("multiselect", {
                attrs: {
                  placeholder: "Search or add vendor",
                  "tag-placeholder": "Search or add vendor",
                  "track-by": "vid",
                  options: _vm.vendorListNew,
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "preserve-search": true,
                  taggable: false,
                  label: "company_name",
                  "preselect-first": false
                },
                on: {
                  input: function($event) {
                    return _vm.getVendorServices()
                  }
                },
                model: {
                  value: _vm.vendorEstimates,
                  callback: function($$v) {
                    _vm.vendorEstimates = $$v
                  },
                  expression: "vendorEstimates"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm.vendorServiceListNew.length > 0
        ? _c("b-card", [
            _vm.vendorServiceListNew.length > 0
              ? _c(
                  "div",
                  { staticClass: "col-12 mb-4 mt-2 pt-3" },
                  [
                    _c("h3", { staticClass: "d-inline" }, [
                      _vm._v(_vm._s(_vm.vendorEstimates.company_name))
                    ]),
                    _c(
                      "vue-json-to-csv",
                      {
                        staticClass: "pull-right mr-2 btn btn-sm btn-blue",
                        attrs: {
                          labels: _vm.labels,
                          "csv-title": "Import Estimates",
                          "json-data": _vm.sendFiltered()
                        }
                      },
                      [
                        _vm._v(
                          "\n          Download CSV Estimates Template\n          "
                        ),
                        _c("i", { staticClass: "fa fa-download" })
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.importEstimates",
                            modifiers: { importEstimates: true }
                          }
                        ],
                        staticClass: "pull-right mr-2 btn-sm",
                        attrs: { variant: "success" }
                      },
                      [
                        _vm._v(" Import Estimates From CSV "),
                        _c("i", { staticClass: "fa fa-plus" })
                      ]
                    ),
                    _c("br"),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Search For Service:")
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-6",
                          attrs: { id: "search_estimate" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.searchBox,
                                expression: "searchBox"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "Search" },
                            domProps: { value: _vm.searchBox },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.searchBox = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.Reload_getVendorServices()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-sync-alt" })]
                          )
                        ]
                      )
                    ]),
                    !_vm.lodash.isEmpty(_vm.vendorEstimates, true)
                      ? _c("div", { staticClass: "table-responsive mt-2" }, [
                          _c(
                            "table",
                            { staticClass: "table table-bordered" },
                            [
                              _c("thead", [
                                _c("tr", { staticClass: "table-success" }, [
                                  _c("td", [_vm._v("Service Name")]),
                                  _c("td", { attrs: { colspan: "2" } }, [
                                    _vm._v("UOM 1")
                                  ]),
                                  _c("td", { attrs: { colspan: "2" } }, [
                                    _vm._v("UOM 2")
                                  ]),
                                  _c("td", { attrs: { colspan: "2" } }, [
                                    _vm._v("UOM 3")
                                  ]),
                                  _c("td", [_vm._v("Actual")]),
                                  _c("td", [_vm._v("Estimate")]),
                                  _c("td", [_vm._v("Difference")])
                                ])
                              ]),
                              _vm._l(_vm.newList, function(vendorService) {
                                return _c(
                                  "tbody",
                                  { attrs: { id: vendorService.ID } },
                                  [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { width: "20%" },
                                          attrs: { rowspan: "3" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(vendorService.servicename)
                                          )
                                        ]
                                      ),
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _vm._v("\n                      UOM: "),
                                        _c("b", [
                                          _vm._v(_vm._s(vendorService.uom1))
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          "\n                      Price per unit: "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  vendorService.pu1
                                                )
                                              )
                                          )
                                        ])
                                      ]),
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _vm._v("\n                      UOM: "),
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(vendorService.uom2) +
                                              " "
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          "\n                      Price per unit: "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  vendorService.pu2
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("td", { attrs: { colspan: "2" } }, [
                                        _vm._v("\n                      UOM: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(vendorService.uom3) + "  "
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          "\n                      Price per unit: "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatPrice(
                                                  vendorService.pu3
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("td", { attrs: { rowspan: "3" } }, [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              (
                                                vendorService.uom1total +
                                                vendorService.uom2total +
                                                vendorService.uom3total
                                              ).toFixed(2)
                                            )
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        { attrs: { rowspan: "3" } },
                                        _vm._l(
                                          _vm.vendorEstimateServiceList,
                                          function(estimatedService) {
                                            return _c("div", [
                                              estimatedService.serviceid ==
                                                vendorService.serviceid &&
                                              estimatedService.afenum ==
                                                _vm.afes.afenum
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                               " +
                                                        _vm._s(
                                                          (
                                                            estimatedService.qty1 *
                                                              vendorService.pu1 +
                                                            estimatedService.qty2 *
                                                              vendorService.pu2 +
                                                            estimatedService.qty3 *
                                                              vendorService.pu3
                                                          ).toFixed(2)
                                                        ) +
                                                        "\n                            "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "td",
                                        { attrs: { rowspan: "3" } },
                                        _vm._l(
                                          _vm.vendorEstimateServiceList,
                                          function(estimatedService) {
                                            return _c("div", [
                                              estimatedService.serviceid ==
                                                vendorService.serviceid &&
                                              estimatedService.afenum ==
                                                _vm.afes.afenum
                                                ? _c("span", [
                                                    (
                                                      vendorService.uom1total +
                                                      vendorService.uom2total +
                                                      vendorService.uom3total
                                                    ).toFixed(2) -
                                                      (
                                                        estimatedService.qty1 *
                                                          vendorService.pu1 +
                                                        estimatedService.qty2 *
                                                          vendorService.pu2 +
                                                        estimatedService.qty3 *
                                                          vendorService.pu3
                                                      ).toFixed(2) <
                                                    0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                              "font-weight":
                                                                "bold"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                    " +
                                                                _vm._s(
                                                                  (
                                                                    vendorService.uom1total +
                                                                    vendorService.uom2total +
                                                                    vendorService.uom3total -
                                                                    (estimatedService.qty1 *
                                                                      vendorService.pu1 +
                                                                      estimatedService.qty2 *
                                                                        vendorService.pu2 +
                                                                      estimatedService.qty3 *
                                                                        vendorService.pu3)
                                                                  ).toFixed(2)
                                                                ) +
                                                                "\n                                 "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              color: "green",
                                                              "font-weight":
                                                                "bold"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                 " +
                                                                _vm._s(
                                                                  (
                                                                    vendorService.uom1total +
                                                                    vendorService.uom2total +
                                                                    vendorService.uom3total -
                                                                    (estimatedService.qty1 *
                                                                      vendorService.pu1 +
                                                                      estimatedService.qty2 *
                                                                        vendorService.pu2 +
                                                                      estimatedService.qty3 *
                                                                        vendorService.pu3)
                                                                  ).toFixed(2)
                                                                ) +
                                                                "\n                                 "
                                                            )
                                                          ]
                                                        )
                                                  ])
                                                : _vm._e()
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]),
                                    _c("tr", { staticClass: "table-info" }, [
                                      _c("td", [_vm._v("Actual")]),
                                      _c("td", [_vm._v("Estimate")]),
                                      _c("td", [_vm._v("Actual")]),
                                      _c("td", [_vm._v("Estimate")]),
                                      _c("td", [_vm._v("Actual")]),
                                      _c("td", [_vm._v("Estimate")])
                                    ]),
                                    _c("tr", [
                                      _c("td", [
                                        vendorService.uom1total != 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom1qtn
                                                    )
                                                )
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom1total.toFixed(
                                                        2
                                                      )
                                                    ) +
                                                    "  "
                                                )
                                              ])
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [_vm._v(" NA")]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [_vm._v(" NA  ")])
                                            ])
                                      ]),
                                      _c("td", [
                                        vendorService.uom1 != null
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.vendorEstimateServiceList,
                                                  function(estimatedService) {
                                                    return _c("div", [
                                                      estimatedService.serviceid ==
                                                        vendorService.serviceid &&
                                                      estimatedService.afenum ==
                                                        _vm.afes.afenum
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                Quantity: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    estimatedService.qty1
                                                                  ) +
                                                                  "  "
                                                              )
                                                            ]),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                                Total: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                "  " +
                                                                  _vm._s(
                                                                    (
                                                                      estimatedService.qty1 *
                                                                      vendorService.pu1
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  }
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-modal",
                                                        rawName:
                                                          "v-b-modal.addEstimateUom1",
                                                        modifiers: {
                                                          addEstimateUom1: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass: "btn primary",
                                                    staticStyle: {
                                                      background: "#73C686",
                                                      color: "white"
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.getNoUoms(
                                                          vendorService
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("+")]
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("td", [
                                        vendorService.uom2total != 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom2qtn
                                                    )
                                                )
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom2total.toFixed(
                                                        2
                                                      )
                                                    ) +
                                                    "  "
                                                )
                                              ])
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [_vm._v(" NA")]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [_vm._v(" NA  ")])
                                            ])
                                      ]),
                                      _c("td", [
                                        vendorService.uom2 != null
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.vendorEstimateServiceList,
                                                  function(estimatedService) {
                                                    return _c("div", [
                                                      estimatedService.serviceid ==
                                                        vendorService.serviceid &&
                                                      estimatedService.afenum ==
                                                        _vm.afes.afenum
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                Quantity: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    estimatedService.qty2
                                                                  ) +
                                                                  "  "
                                                              )
                                                            ]),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                                Total: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                "  " +
                                                                  _vm._s(
                                                                    (
                                                                      estimatedService.qty2 *
                                                                      vendorService.pu2
                                                                    ).toFixed(2)
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  }
                                                ),
                                                _c("div", [
                                                  _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.addEstimateUom2",
                                                          modifiers: {
                                                            addEstimateUom2: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "btn primary",
                                                      staticStyle: {
                                                        background: "#73C686",
                                                        color: "white"
                                                      },
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.getNoUoms(
                                                            vendorService
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("+")]
                                                  )
                                                ])
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("td", [
                                        vendorService.uom3total != 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom3qtn
                                                    )
                                                )
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      vendorService.uom3total.toFixed(
                                                        2
                                                      )
                                                    ) +
                                                    "  "
                                                )
                                              ])
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                        Quantity: "
                                              ),
                                              _c("b", [_vm._v(" NA")]),
                                              _c("br"),
                                              _vm._v(
                                                "\n                        Total: "
                                              ),
                                              _c("b", [_vm._v(" NA  ")])
                                            ])
                                      ]),
                                      _c("td", [
                                        vendorService.uom3 != null
                                          ? _c(
                                              "div",
                                              [
                                                _vm._l(
                                                  _vm.vendorEstimateServiceList,
                                                  function(estimatedService) {
                                                    return _c("div", [
                                                      estimatedService.serviceid ==
                                                        vendorService.serviceid &&
                                                      estimatedService.afenum ==
                                                        _vm.afes.afenum
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                                Quantity: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    estimatedService.qty3
                                                                  ) +
                                                                  "  "
                                                              )
                                                            ]),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                                Total: "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                "  " +
                                                                  _vm._s(
                                                                    (
                                                                      estimatedService.qty3 *
                                                                      vendorService.pu3
                                                                    ).toFixed(2)
                                                                  )
                                                              )
                                                            ])
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  }
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-modal",
                                                        rawName:
                                                          "v-b-modal.addEstimateUom3",
                                                        modifiers: {
                                                          addEstimateUom3: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass: "btn primary",
                                                    staticStyle: {
                                                      background: "#73C686",
                                                      color: "white"
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.getNoUoms(
                                                          vendorService
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("+")]
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "addEstimateUom1",
          attrs: {
            id: "addEstimateUom1",
            title: "Add/Update Estimate 1",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("h5", [_vm._v("Enter quanity:")])
            ]),
            _c("div", { staticClass: "col-md-12 form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.uom1Quantity,
                    expression: "uom1Quantity"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.uom1Quantity },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.uom1Quantity = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success", type: "submit", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.createEstimateUom1()
                      }
                    }
                  },
                  [_vm._v("Add "), _c("i", { staticClass: "fa fa-plus" })]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "addEstimateUom2",
          attrs: {
            id: "addEstimateUom2",
            title: "Add/Update Estimate 2",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("h5", [_vm._v("Enter quanity:")])
            ]),
            _c("div", { staticClass: "col-md-12 form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.uom2Quantity,
                    expression: "uom2Quantity"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.uom2Quantity },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.uom2Quantity = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success", type: "submit", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.createEstimateUom2()
                      }
                    }
                  },
                  [_vm._v("Add "), _c("i", { staticClass: "fa fa-plus" })]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "addEstimateUom3",
          attrs: {
            id: "addEstimateUom3",
            title: "Add/Update Estimate 3",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-8" }, [
              _c("h5", [_vm._v("Enter quanity:")])
            ]),
            _c("div", { staticClass: "col-md-12 form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.uom3Quantity,
                    expression: "uom3Quantity"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.uom3Quantity },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.uom3Quantity = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "success", type: "submit", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.createEstimateUom3()
                      }
                    }
                  },
                  [_vm._v("Add "), _c("i", { staticClass: "fa fa-plus" })]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "importEstimates",
          attrs: {
            id: "importEstimates",
            "hide-footer": "",
            title: "Import Estimates",
            "title-tag": "h2",
            centered: "",
            size: "lg"
          },
          on: {
            shown: function($event) {
              return _vm.clearImport()
            }
          }
        },
        [
          _c("div", { staticClass: "parse mt-2 mb-4 row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("h5", [_vm._v("CSV Files Only")]),
              _c("input", {
                ref: "csvFile",
                staticClass: "mt-2",
                attrs: { id: "fileInput", type: "file" },
                on: { change: _vm.upload }
              })
            ]),
            _vm.doc.length > 0
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("h5", [_vm._v("Import")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          return _vm.importEstimates(_vm.doc)
                        }
                      }
                    },
                    [_vm._v("Import")]
                  )
                ])
              : _vm._e(),
            _vm.successServices.length > 0
              ? _c("div", { staticClass: "col-md-12 mt-4" }, [
                  _c("h5", [_vm._v("Successfully Imported: ")]),
                  _c(
                    "ul",
                    _vm._l(_vm.successServices, function(success) {
                      return _c("li", [
                        _vm._v("\n          " + _vm._s(success) + "\n        ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.serviceErrors.length > 0
              ? _c("div", { staticClass: "col-md-12 mt-4" }, [
                  _c("h5", [_vm._v("Unsuccessful: ")]),
                  _c(
                    "ul",
                    _vm._l(_vm.serviceErrors, function(error) {
                      return _c("li", [
                        _vm._v("\n          " + _vm._s(error) + "\n        ")
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }